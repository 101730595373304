body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh; 
  width: 100vw;
  overflow:hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.avatar-video-player audio::-webkit-media-controls-timeline,
.avatar-video-player video::-webkit-media-controls-timeline {
  display: none;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;

}

.avatar-video-player video::-webkit-media-controls-enclosure {
  overflow: hidden !important;
}

/* .avatar-video-player video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
} 
*/

.avatar-video-player video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.avatar-video-player video::-webkit-media-controls-mute-button {
  display: none;

}

.avatar-video-player  video::-webkit-media-controls-play-button {
  display: none;
}



.avatar-video-player video::-webkit-media-controls-current-time-display{
  z-index:2000;
}
.avatar-video-player video::-webkit-media-controls-time-remaining-display {
  z-index:2000;
}


/* These are the controls of the video if we want to change anything or remove anything */
/* video::-webkit-media-controls-play-button {}
video::-webkit-media-controls-timeline {}
video::-webkit-media-controls-current-time-display{}
video::-webkit-media-controls-time-remaining-display {}

video::-webkit-media-controls-toggle-closed-captions-button {}
video::-webkit-media-controls-volume-slider {} */